<template>
  <v-row class="mt-5 mb-5">
    <v-col md="12">
      <v-text-field label="Servername" dense :value="test" />
    </v-col>
    <v-col md="12">
      Betriebssystem
      <v-select v-model="template" :items="templates.map(t => ({ text: t.title , value: t.id}))" />
    </v-col>
    <v-col md="12">
      <v-btn color="primary" @click="test">Server Neuinstallieren</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'

export default {
  data() {
    return {
      templates: [],
      template: 0
    }
  },
  created() {
    xeonserv().get('/minecraftserver-templates').then(res => {
      this.templates = res.data.data
      this.template = this.templates[0].id
    })
  },
  methods: {
    /*order() {
      this.ordering = true
      xeonserv().post('/minecraftservers', {

        disk: this.disk,
        memory: this.memory,
        template_id: this.template,
        duration: this.duration
      }).then(res => {
        this.server = res.data.data
        this.step = 3
        this.$store.dispatch('loadCurrentUser')
      }, () => this.ordering = false)
    },
    priceDisplay(prop, amount) {
      const p = prop == 'total' ? this.price : (amount * this.pricing[prop] * (this.duration / 30))
      return p.toFixed(2).replace('.', ',')
    }*/
  }
}
</script>