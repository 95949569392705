function split(seconds) {
    let minutes = Math.floor(seconds / 60)
    seconds = seconds % 60
    let hours = Math.floor(minutes / 60)
    minutes = minutes % 60
    let days = Math.floor(hours / 24)
    hours = hours % 24
    let months = Math.floor(days / 30)
    days = days % 30
    let years = Math.floor(months / 12)
    months = months % 12
    return { years, months, days, hours, minutes, seconds }
}

function format(interval, max = 3, raw = false) {
    if(typeof interval == 'number')
        return format(split(interval), max, raw)
    const components = []
    if(components.length < max && interval.years > 0)
        components.push(interval.years + ' Jahr' + (interval.years > 1 ? 'e' : ''))
    if(components.length < max && interval.months > 0)
        components.push(interval.months + ' Monat' + (interval.months > 1 ? 'e' : ''))
    if(components.length < max && interval.days > 0)
        components.push(interval.days + ' Tag' + (interval.days > 1 ? 'e' : ''))
    if(components.length < max && interval.hours > 0)
        components.push(interval.hours + ' Stunde' + (interval.hours > 1 ? 'n' : ''))
    if(components.length < max && interval.minutes > 0)
        components.push(interval.minutes + ' Minute' + (interval.minutes > 1 ? 'n' : ''))
    if(components.length < max && interval.seconds > 0)
        components.push(interval.seconds + ' Sekunde' + (interval.seconds > 1 ? 'n' : ''))
    if(raw)
        return components
    return components.join(', ')
}

function until(date) {
    if(!date)
        return until(new Date())
    if(typeof date == 'string' || typeof date == 'number')
        return until(new Date(date))
    return Math.floor((date.getTime() - (new Date().getTime())) / 1000)
}

export default {
    split,
    format,
    until
}