<template>
    <v-row class="mt-5 mb-5">
        <v-col md="8">
            <v-row dense>
                <v-col md="12">
                    <v-text-field label="Name" outlined readonly dense :value="status.hostname" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="Status" outlined readonly dense :value="displayStatus" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="RAM" outlined readonly dense :value="server.memory + ' GB'" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="SSD" outlined readonly dense :value="server.disk + ' GB'" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="IP-Adresse" outlined readonly dense :value="status.address + ':' + status.port" />
                </v-col>
                <!--<v-col md="12">
                    <v-text-field label="Restlaufzeit" outlined readonly dense :value="remainingTime" />
                </v-col>-->
                <v-col md="12" class="text-center">
                    RAM Auslastung ({{ memoryUsageGB.toFixed(3).replace('.', ',') }}  GB, {{ (memoryUsage * 100).toFixed(1).replace('.', ',') }} %)
                    <v-progress-linear :value="memoryUsage * 100" class="mb-5"></v-progress-linear>
                </v-col>
            </v-row>
        </v-col>
        <v-col md="4">
            <!--<v-btn block v-if="status.status == 'OFFLINE'" class="mb-2 white--text" color="green" @click="startServer()">Starten</v-btn>
            <v-btn block v-if="status.status == 'ONLINE'" class="mb-2 white--text" color="orange" @click="restartServer()">Neustart</v-btn>
            <v-btn block v-if="status.status == 'ONLINE'" class="mb-2 white--text" color="red" @click="shutdownServer()">Herunterfahren</v-btn>
            <v-btn block v-if="status.status == 'ONLINE'" class="mb-2 white--text" color="red" @click="stopServer()">Stoppen</v-btn>
            <v-btn block class="mb-2" color="primary">Verlängern</v-btn>
            <MinecraftServerUpgrade :server="server" :service="service" />-->
          <v-btn block class="mb-2 white--text" color="green" @click="startServer()">Starten</v-btn>
          <v-btn block class="mb-2 white--text" color="orange" @click="restartServer()">Neustart</v-btn>
          <v-btn block class="mb-2 white--text" color="red" @click="shutdownServer()">Herunterfahren</v-btn>
          <v-btn block class="mb-2 white--text" color="red" @click="stopServer()">Stoppen</v-btn>
          <v-btn block class="mb-2" color="primary">Verlängern</v-btn>
          <MinecraftServerUpgrade :server="server" :service="service" />
        </v-col>
    </v-row>
</template>

<script>

import xeonserv from '../../../apis/xeonserv'
import interval from '../../../util/interval'
import MinecraftServerUpgrade from './MinecraftServerUpgrade'

export default {
    components: { MinecraftServerUpgrade },
    data() {
        return {
            now: new Date(),
            hidePassword: true,
            //addresses: []
        }
    },
    
    timers: {
        updateNow: { time: 1000, autostart: true, repeat: true, callback() { this.now = new Date() }}
    },
    props: {
        server: {
            type: Object
        },
        status: {
            type: Object
        },
        service: {
            type: Object
        }
    },
    methods: {
        startServer() {
            xeonserv().post('/minecraftservers/' + this.server.id + '/start').then(() => this.$parent.$emit('reloadstatus'))
        },
        stopServer() {
            xeonserv().post('/minecraftservers/' + this.server.id + '/stop').then(() => this.$parent.$emit('reloadstatus'))
        },
        restartServer() {
            xeonserv().post('/minecraftservers/' + this.server.id + '/restart').then(() => this.$parent.$emit('reloadstatus'))
        },
        shutdownServer() {
            xeonserv().post('/minecraftservers/' + this.server.id + '/shutdown').then(() => this.$parent.$emit('reloadstatus'))
        }
    },
    computed: {
        displayStatus() {
            if(this.service.expired)
                return 'Abgelaufen'
            switch(this.status.status) {
              case 'STARTING':
                return 'Startet...'
              case 'ONLINE':
                return 'Online'
              case 'STOPPING':
                return 'Stoppt...'
              case 'OFFLINE':
                return 'Offline'
            }
            return 'Unbekannt'
        },
        memoryUsageGB() {
          return this.status.memory / 1073741824
        },
        memoryUsage() {
          return this.status.memory / (this.server.memory * 1073741824)
        },
        remainingTime() {
              this.now;
              return interval.format(interval.until(this.service.expire_at))
        }
    }
}
</script>