<template>
    <v-dialog max-width="600px" v-model="open">
        <template v-slot:activator="{ on, attrs }">
            <v-btn block class="mb-2" color="primary" v-bind="attrs" v-on="on">Up-/Downgraden</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Minecraft Server Up/Downgrade</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    Arbeitsspeicher
                    <v-slider v-model="memory" min="1" max="64" :tick-labels="[1,4,8,12,16,24,32,48,64]" tick-size="0" />
                    SSD
                    <v-slider v-model="disk" :min="server.disk" max="200" :tick-labels="[10,20,30,40,50,100,150,200]" tick-size="0" />

                    <div class="text-center mt-3 mb-3" style="font-size: 25px; font-weight: 500;">
                        Preis: {{ price.toFixed(2).replace('.', ',') }} €
                    </div>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="open = false">Abbrechen</v-btn>
                <v-btn v-if="price >= 0" @click="upgrade()" color="primary">Kostenpflichtig Upgraden</v-btn>
                <v-btn v-else @click="upgrade()" color="primary">Downgraden</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'

export default {
    props: {
        server: {
            type: Object
        },
        service: {
            type: Object
        }
    },
    data() {
        return {
            open: false,
            pricing: {
                base: 0,
                memory: 0,
                disk: 0
            },
            memory: this.server.memory,
            disk: this.server.disk
        }
    },
    created() {
        xeonserv().get('/pricing/MINECRAFT_SERVER').then(res => this.pricing = res.data.data)
    },
    computed: {
        price() {
            const duration = (new Date(this.service.expire_at).getTime() - new Date().getTime()) / 86400000
            const memoryDiff = this.memory - this.server.memory
            const diskDiff = this.disk - this.server.disk
            return ((memoryDiff * this.pricing.memory) + (diskDiff * this.pricing.disk)) * (duration / 30)
        }
    },
    methods: {
        upgrade() {
            xeonserv().put('/minecraftservers/' + this.server.id, {
                memory: this.memory,
                disk: this.disk
            }).then(res => {
                console.log(this.$parent.$parent.$parent.$parent)
                this.$parent.$parent.$parent.$parent.$emit('setserver', res.data.data)
                this.$store.dispatch('loadCurrentUser')
                this.open = false
            })
        }
    },
    watch: {
        server() {
            this.memory = this.server.memory
            this.disk = this.server.disk
        }
    }
}
</script>